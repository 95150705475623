<template>
  <div class="pdp-quantity-container">
    <div class="title-semibold mb-4">
      จำนวน
    </div>
    <div class="pdp-quantity">
      <button
        @click="decrement"
        class="quantity-button disabled"
        disabled
      >
        <img :src="minusIconBase64" alt="minus" />
      </button>
      <input
        type="number"
        v-model="quantity"
        class="quantity-input"
        disabled
        min="1"
      />
      <button
        @click="increment"
        class="quantity-button disabled"
        disabled
      >
        <img :src="plusiIconBase64" alt="plus" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      quantity: 1,
      plusiIconBase64:
        'data:image/svg+xml;base64,PHN2ZyBkYXRhLXYtNjdhYTJkZTg9IiIgd2lkdGg9IjEwIiBoZWlnaHQ9IjkiIHZpZXdCb3g9IjAgMCAxMCA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSJpY29uIj48cGF0aCBkPSJNNS45OTIxOSA1LjU2NDQ1SDkuMjE4NzVWMy41NDg4M0g1Ljk5MjE5VjAuMzYxMzI4SDRWMy41NDg4M0gwLjc4MTI1VjUuNTY0NDVINFY4Ljc1OTc3SDUuOTkyMTlWNS41NjQ0NVoiIGZpbGw9IiMxMjEyMTIiIGZpbGwtb3BhY2l0eT0iMC43NSI+PC9wYXRoPjwvc3ZnPgo=',
      minusIconBase64:
        'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIzIiB2aWV3Qm94PSIwIDAgOCAzIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSJpY29uIiBkYXRhLXYtNjdhYTJkZTg9IiI+PHBhdGggZD0iTTggMi42MzQ3N1YwLjUwMTk1M0gwVjIuNjM0NzdIOFoiIGZpbGw9IiMxMjEyMTIiIGZpbGwtb3BhY2l0eT0iMC43NSI+PC9wYXRoPjwvc3ZnPgo=',
    }
  },
  methods: {
    increment() {
      this.quantity++
    },
    decrement() {
      if (this.quantity > 1) {
        this.quantity--
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.pdp-quantity
    display flex
    justify-content center
    height 48px
    max-width 188px
    padding 4px
    width 100%
    font-size 16px
    font-weight 500
    line-height 18.4px
    border 1px solid rgb(0, 0, 0)
    border-radius 8px

.quantity-button
    align-items center
    display flex
    height 40px
    justify-content center
    margin 0
    overflow visible
    padding 0
    width 45px
    font-size 14px
    font-weight 600
    letter-spacing -0.24px
    line-height 18px
    text-align center
    color rgb(0, 0, 0)
    background-color rgba(0, 0, 0, 0)
    border 0
    cursor not-allowed
    transition all 0.15s ease
    appearance button
    text-rendering auto
    border-radius 32px
    overflow-wrap break-word
    padding 0
    &.disabled
        opacity 0.5

.quantity-input
    flex auto
    border none
    font-family: "SF Pro Text";
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25012rem;
    padding: 10px 8px;
    width: 3.5rem;
    border: none;
    color: #000;
    text-align: center;
</style>
